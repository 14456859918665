function menuNavigation(item) {
    var menu = document.getElementById("textbox1");
    var menuItems = menu.getElementsByClassName("contentblock")
    var activeItem = document.getElementById(item);
    var textbox1 = document.getElementById("textbox1");
    for (var i = 0; i < menuItems.length; i++) {
        menuItems[i].className = "contentblock";
    }
    activeItem.className = "contentblock active";
    if (item == "main") {
        textbox1.style.height = "100%";
        textbox1.style.width = "100%";
        textbox1.style.top = "0em";
        textbox1.style.left = "0em";
        textbox1.style.right = "0em";
        textbox1.style.bottom = "0em";
        textbox1.style.padding = "0em";
        textbox1.style.borderRadius = "0em";
    } else {
        textbox1.style.height = "fit-content";
        textbox1.style.width = "fit-content";
        textbox1.style.top = "3em";
        textbox1.style.left = "3em";
        textbox1.style.right = "3em";
        textbox1.style.bottom = "3em";
        textbox1.style.padding = "2em";
        textbox1.style.borderRadius = "0.5em";
    }
}